/* CartPage.css */

.cart-page {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background-color: #f1f1f1;
  font-family: 'Arial', sans-serif;
}

.cart-header {
  background-color: #0056b3;
  color: #fff;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 60px;
}

.navbar-logo {
  height: 40px;
  margin-right: 10px;
}

.navbar-title {
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.logo-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
}

.cart-header h2 {
  flex: 1;
  text-align: center;
  font-size: 24px;
}

.back-button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.cart-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 60%;
  max-width: 800px;
  margin-top: 100px;
}

.cart-items {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.cart-item-left {
  display: flex;
  align-items: center;
  width: 70%;
}

.cart-item-img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 5px;
}

.cart-item-details {
  display: flex;
  flex-direction: column;
}

.cart-item-description {
  font-size: 18px;
  margin: 0;
  font-weight: bold;
}

.cart-item-price {
  font-size: 20px;
  color: #0056b3;
  margin-top: 5px;
}

.cart-item-right {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.quantity-control {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.quantity-control button {
  width: 30px;
  height: 30px;
  background-color: #0056b3;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  border-radius: 5px;
}

.quantity-control button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.quantity-display {
  margin: 0 10px;
  font-size: 16px;
  font-weight: bold;
}

.remove-button {
  background-color: #ff0000;
  color: white;
  border: none;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 5px;
}

.remove-button:hover {
  background-color: #cc0000;
}

.cart-summary {
  text-align: center;
  margin-top: 20px;
}

.payment-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 18px;
}

.payment-button:hover {
  background-color: #218838;
}

.empty-cart-message {
  text-align: center;
  font-size: 20px;
  color: #666;
  margin-top: 20px;
}
