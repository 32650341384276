.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Fondo semi-translúcido */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Asegura que el modal esté por encima de otros elementos */
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;  /* Ajusta el tamaño para hacerlo más responsivo */
    max-width: 500px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    position: relative;
    max-height: 80vh;  /* Limita la altura del modal */
    overflow-y: auto;  /* Agrega scroll si el contenido excede la altura */
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #333;
    font-weight: bold;
}

.close-btn:hover {
    color: red; /* Color cuando el mouse pasa sobre la X */
}

.product-rating {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.star {
    font-size: 30px;
    cursor: pointer;
    color: #ccc;
}

.star.filled {
    color: gold;
}

textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    resize: none;  /* Evita que el campo de texto se redimensione */
    min-height: 100px;  /* Asegura que tenga una altura adecuada */
    box-sizing: border-box; /* Asegura que el padding no afecte el tamaño */
}

button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;  /* Hace que el botón ocupe todo el ancho */
}

.previous-comments {
    margin-top: 20px;
    max-height: 200px;  /* Limita la altura de los comentarios previos */
    overflow-y: auto;  /* Añade scroll si los comentarios exceden la altura */
}

.comment-card {
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
    box-sizing: border-box; /* Asegura que el padding no afecte el tamaño */
}

.comment-card p {
    margin: 5px 0;
}

.comment-card span {
    color: gold;
    font-weight: bold;
}
