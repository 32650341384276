/* AddProductPage.css */

.add-product-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: #f4f4f9;
  font-family: 'Arial', sans-serif;
}

.product-details-columns {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.product-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.add-product-header {
  background-color: #0056b3;
  color: white;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 1000;
  height: 60px;
}

.navbar-logo {
  height: 40px;
  margin-right: 10px;
}

.navbar-title {
  font-size: 20px;
  font-weight: bold;
  color: white;
}

/* Añade estos estilos a tu archivo CSS */
.navbar-button {
  background-color: #0056b3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Sombreado similar */
  font-weight: bold;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.navbar-button:hover {
  background-color: #004494; /* Color más oscuro al pasar el cursor */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.navbar-button:focus {
  outline: none;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15); /* Mantiene el sombreado al enfocarse */
}


.header-buttons {
  display: flex;
  align-items: center;
}

.user-info {
  position: relative;
}

.user-name {
  background-color: #0056b3;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.back-button {
  background-color: #ff0000;
  color: white;
  padding: 10px;
  margin-left: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  padding: 10px;
  border: none;
  background-color: white;
  cursor: pointer;
  width: 100%;
  text-align: left;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.add-product-content {
  margin-top: 100px;
  width: 80%;
  text-align: center;
}

.product-table-container {
  max-height: 200px; /* Limita a 5 filas */
  overflow-y: auto;
  border: 1px solid #ddd;
  margin-top: 20px;
}

.product-table {
  width: 100%;
  border-collapse: collapse;
}

.product-table th, .product-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.product-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.product-management-section {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  position: relative;
}

.search-section {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
}

.search-input {
  padding: 8px;
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 180px;
}

.search-button {
  padding: 8px; /* Reducir el padding para hacerlo más pequeño */
  font-size: 14px; /* Ajustar el tamaño del texto o icono */
  width: auto; /* Asegurar que no ocupe demasiado espacio */
  height: auto; /* Para que se ajuste al contenido */
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 4px;
}


.action-buttons {
  display: flex;
  justify-content: center;
  gap: 5px; /* Reducir la separación entre los botones */
}

.action-button {
  padding: 6px 10px; /* Reducir el tamaño de los botones */
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  max-width: 100px;
}

.action-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.product-details {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
}

.product-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.product-field {
  display: flex;
  align-items: center;
  gap: 10px;
}

.product-field.small label {
  min-width: 70px; /* Tamaño reducido para las etiquetas pequeñas */
}

.product-field label {
  font-weight: bold;
  min-width: 100px;
  text-align: right;
}

.product-input-small {
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 50%; /* Tamaño reducido para los campos pequeños */
  background-color: #f9f9f9;
  font-size: 14px;
}

.product-input {
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  background-color: #f9f9f9;
  font-size: 14px;
}

.description-input {
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  height: 60px;
  background-color: #f9f9f9;
  font-size: 14px;
  resize: none;
}

.confirm-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.cancel-button,
.confirm-button {
  padding: 6px 10px; /* Tamaño reducido */
  font-size: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-button {
  background-color: #ff4d4d;
  color: white;
}

.confirm-button {
  background-color: #007bff;
  color: white;
}

.confirmation-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  text-align: center;
  z-index: 2000;
}

.confirmation-modal p {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
}

input[type="file"] {
  padding: 6px;
  font-size: 14px;
}
