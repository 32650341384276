.eliminar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Fondo oscuro translúcido */
    z-index: 1000;
}

.eliminar-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    width: 300px;
    text-align: center;
    z-index: 1001; /* Encima del overlay */
}

.eliminar-message {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.eliminar-buttons {
    display: flex;
    justify-content: space-around;
}

.eliminar-confirm {
    background-color: #d9534f;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.eliminar-confirm:hover {
    background-color: #c9302c;
}

.eliminar-cancel {
    background-color: #5cb85c;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.eliminar-cancel:hover {
    background-color: #4cae4c;
}

.eliminar-feedback {
    margin-top: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #555;
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
